import React, { useState, useCallback, useMemo } from 'react';
import { Row, Col, Card, CardBody, Container, Spinner } from 'reactstrap';

import * as pushNotificationsActions from '../../store/pushNotifications/actions';
import * as leadsActions from '../../store/leads/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectLead } from '../../selectors/lead';
import Modal from '../../components/Common/Modal/Modal';
import ShowSingleElement from '../../components/Common/ShowSingleElement';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DateRangeFilter from '../../components/Common/DateRangeFilter/DateRangeFilter';
import PushNotificationMetrics from '../../components/PushNotifications/PushNotificationMetrics';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import createTableColumns from '../../components/PushNotifications/tableColumns';
import {
  ExportButtonPlacement,
  ToggleColumnButton,
} from '../../components/Common/DataTable/tableActionButtons';
import { addOrRemove } from '../../helpers/sharedFunction';
import moment from 'moment';

const List = (props) => {
  const [selectedLead, setSelectedLead] = useState(null);
  const [leadModal, setLeadModal] = useState(false);
  const [selectedPushNotification, setSelectedPushNotification] =
    useState(null);
  const [pushNotificationModal, setPushNotificationModal] = useState(false);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const loading = useSelector((state) => state.PushNotification.loading);
  const pushNotifications = useSelector(
    (state) => state.PushNotification.pushNotifications,
  );
  const showSelectedLead = useSelector(selectLead(selectedLead));

  const startDate = useSelector((state) => state.PushNotification.startDate);
  const endDate = useSelector((state) => state.PushNotification.endDate);
  const momentStartDate = startDate ? moment(startDate) : null;
  const momentEndDate = endDate ? moment(endDate) : null;

  const maxDate = moment.utc();
  const minDate = moment.utc('04/07/2020', 'DD/MM/YYYY');

  const [selectedFilters, setSelectedFilters] = useState({
    startDate: momentStartDate || moment.utc().startOf('day'),
    endDate: momentEndDate || moment.utc(),
  });

  const dispatch = useDispatch();

  const refresh = useCallback(({ startDate, endDate }) => {
    const selectedStartDate = startDate.utc().startOf('day').toDate();
    const selectedEndDate = endDate.utc().endOf('day').toDate();
    dispatch(
      pushNotificationsActions.fetchPushNotifications(
        selectedStartDate,
        selectedEndDate,
      ),
    );
    setSelectedFilters({ startDate, endDate });
  });

  const openLeadModal = (pushNotification) => {
    setSelectedLead(pushNotification.leadId);
    !showSelectedLead &&
      dispatch(leadsActions.fetchSingleLead(pushNotification.leadId));
    setLeadModal(true);
  };

  const openPushNotificationModal = (pushNotification) => {
    setSelectedPushNotification(pushNotification);
    setPushNotificationModal(true);
  };

  const columns = useMemo(
    () => createTableColumns(openLeadModal, openPushNotificationModal),
    [],
  );
  const [hiddenColumns, setHiddenColumns] = useState(
    columns.filter(({ hidden }) => !!hidden).map(({ name }) => name),
  );
  const handleToggleColumn = (name) => {
    setHiddenColumns(addOrRemove(hiddenColumns, name));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Push Notifications"
            breadcrumbItem="List"
            onRefresh={() => refresh(selectedFilters)}
            loading={loading}
          />
          <DateRangeFilter
            onUpdate={refresh}
            minDate={minDate}
            maxDate={maxDate}
            startDate={selectedFilters.startDate}
            endDate={selectedFilters.endDate}
            loading={loading}
          />
          <Row>
            <PushNotificationMetrics loading={loading} />
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="align-items-center justify-content-end mb-2">
                    <Spinner
                      color="secondary"
                      animation="border"
                      hidden={!loading}
                    />
                    <ToggleColumnButton
                      columns={columns}
                      hiddenColumns={hiddenColumns}
                      onToggleColumn={handleToggleColumn}
                    />
                    <ExportButtonPlacement />
                  </Row>
                  <DataTableNext
                    loading={loading}
                    rows={pushNotifications}
                    columns={columns}
                    filters={filters}
                    onFiltersChange={setFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    hiddenColumns={hiddenColumns}
                    onHiddenColumnsChange={setHiddenColumns}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="xl"
            title="Lead"
            isOpen={leadModal}
            toggle={() => setLeadModal(!leadModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement
              element={showSelectedLead}
              icon="bx bx-user-pin"
            />
          </Modal>
          <Modal
            size="lg"
            title="Push Notification"
            isOpen={pushNotificationModal}
            toggle={() => setPushNotificationModal(!pushNotificationModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement
              element={selectedPushNotification}
              icon="bx bx-notification"
            />
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default List;
