import React from 'react';
import { Col } from 'reactstrap';

import { useSelector } from 'react-redux';
import {
  countLeads,
  countLeadsOnDanteCallCenter,
  countLeadsOnChiedimiCallCenter,
  countNoTimingLeads,
  countCertifiedLeads,
} from '../../selectors/lead';

import MiniCard from '../../components/Common/MiniCard';

const CallCenterMetrics = (props) => {
  const leads = useSelector(countLeads);
  const countLeadsChiedimi = useSelector(countLeadsOnChiedimiCallCenter);
  const countLeadsDante = useSelector(countLeadsOnDanteCallCenter);
  const qualifyLeads = ((countLeadsChiedimi + countLeadsDante) / leads) * 100;
  const noTimingLeads = useSelector(countNoTimingLeads);
  const percentageNoTimingLeads = (noTimingLeads / leads) * 100;
  const certifiedLeads = useSelector(countCertifiedLeads);
  const percentageCertifiedLeads = (certifiedLeads / leads) * 100;

  const callCenterMetrics = [
    {
      title: 'Leads',
      text: leads,
      color: 'info',
      icon: 'bx bx-user-pin',
    },
    {
      title: 'Chiedimi Call Center',
      text: countLeadsChiedimi,
      color: 'secondary',
      icon: 'bx bx-support',
    },
    /*
    {
      title: 'Dante Call Center',
      text: countLeadsDante,
      color: 'secondary',
      icon: 'bx bx-user-pin',
    },*/
    {
      title: 'Lead to qualify',
      text: `${isFinite(qualifyLeads) ? qualifyLeads.toFixed(2) : 0} %`,
      color: 'warning',
      icon: 'bx bx-user-check',
    },
    {
      title: 'No timing',
      text: `${
        isFinite(percentageNoTimingLeads)
          ? percentageNoTimingLeads.toFixed(2)
          : 0
      } %`,
      color: 'danger',
      icon: 'bx bx-user-x',
    },
    {
      title: 'Certified Leads',
      text: `${
        isFinite(percentageCertifiedLeads)
          ? percentageCertifiedLeads.toFixed(2)
          : 0
      } %`,
      color: 'success',
      icon: 'bx bx-user-check',
    },
  ];

  return (
    <>
      {callCenterMetrics.map((metric, index) => (
        <Col key={'mainMetric_' + index}>
          <MiniCard {...metric} loading={props.loading} />
        </Col>
      ))}
    </>
  );
};

export default CallCenterMetrics;
