import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Card, CardBody, Container, Spinner } from 'reactstrap';

import * as usersActions from '../../store/users/actions';
import * as acquisitionOrdersActions from '../../store/acquisitionOrders/actions';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../components/Common/Modal/Modal';
import ShowSingleElement from '../../components/Common/ShowSingleElement';
import FormUser from '../../components/Users/FormUser';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import createTableColumns from '../../components/Users/tableColumns';
import {
  ExportButtonPlacement,
  ToggleColumnButton,
} from '../../components/Common/DataTable/tableActionButtons';
import { addOrRemove } from '../../helpers/sharedFunction';

const List = (props) => {
  const loading = useSelector((state) => state.User.loading);
  const loadingAcquisitionOrders = useSelector(
    (state) => state.AcquisitionOrder.loading,
  );
  const users = useSelector((state) => state.User.users);
  const acquisitionOrders = useSelector(
    (state) => state.AcquisitionOrder.acquisitionOrders,
  );
  const user = useSelector((state) => state.Auth.admin);
  const companyId = useSelector((state) => state.Dashboard.companyId);

  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(usersActions.syncUsers());
    dispatch(acquisitionOrdersActions.syncAcquisitionOrders());
  }, []);

  const openEditModal = (user = null) => {
    setSelectedUser(user);
    setEditModal(true);
  };

  const openViewModal = (user) => {
    setSelectedUser(user);
    setViewModal(true);
  };

  const columns = useMemo(
    () =>
      createTableColumns(
        openViewModal,
        openEditModal,
        acquisitionOrders,
        user,
        companyId,
      ),
    [acquisitionOrders, user, companyId],
  );
  const [hiddenColumns, setHiddenColumns] = useState(
    columns.filter(({ hidden }) => !!hidden).map(({ name }) => name),
  );
  const handleToggleColumn = (name) => {
    setHiddenColumns(addOrRemove(hiddenColumns, name));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Users" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="align-items-center justify-content-end mb-2">
                    <Spinner
                      color="secondary"
                      animation="border"
                      hidden={!loading}
                    />
                    <ToggleColumnButton
                      columns={columns}
                      hiddenColumns={hiddenColumns}
                      onToggleColumn={handleToggleColumn}
                    />
                    <ExportButtonPlacement />
                  </Row>
                  <DataTableNext
                    loading={loading}
                    rows={users}
                    columns={columns}
                    filters={filters}
                    onFiltersChange={setFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    hiddenColumns={hiddenColumns}
                    onHiddenColumnsChange={setHiddenColumns}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="lg"
            title="User"
            isOpen={viewModal}
            toggle={() => setViewModal(!viewModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement element={selectedUser} />
          </Modal>
          <Modal
            size="xl"
            title="User"
            isOpen={editModal}
            toggle={() => setEditModal(!editModal)}
            scrollable={false}>
            <FormUser
              closeModal={() => setEditModal(false)}
              create={!selectedUser}
              loading={loading || loadingAcquisitionOrders}
              acquisitionOrders={acquisitionOrders}
              user={selectedUser}
            />
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default List;
