import React, { useState, useMemo } from 'react';
import { Row, Card, CardBody, CardTitle, Spinner } from 'reactstrap';

import { useSelector, useDispatch } from 'react-redux';
import * as leadsActions from '../../store/leads/actions';
import { leadsWithErrors } from '../../selectors/lead';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import Modal from '../Common/Modal/Modal';
import Alert from '../Common/Modal/Alert';
import FormLead from '../Leads/FormLead';
import ShowSingleElement from '../Common/ShowSingleElement';
import createTableColumns from '../Leads/tableColumns';
import { ToggleColumnButton } from '../../components/Common/DataTable/tableActionButtons';
import { useTranslation } from 'react-i18next';
import { addOrRemove } from '../../helpers/sharedFunction';

const ErrorLeads = (props) => {
  const [selectedLead, setSelectedLead] = useState(null);
  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [reprocessLeadAlert, setReprocessLeadAlert] = useState(false);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const leads = useSelector(leadsWithErrors);
  const campaigns = useSelector((state) => state.Campaign.campaigns);
  const showSensitiveData = useSelector(
    (state) => state.Layout.showSensitiveData,
  );
  const loading = useSelector((state) => state.Leads.loading);

  const dispatch = useDispatch();

  const openViewModal = (lead) => {
    setSelectedLead(lead);
    setViewModal(true);
  };

  const openEditModal = (lead) => {
    setSelectedLead(lead);
    setEditModal(true);
  };

  const openReprocessLeadAlert = (lead) => {
    setSelectedLead(lead);
    setReprocessLeadAlert(true);
  };

  const handleReprocessLead = () => {
    let lead = { id: selectedLead.id, error: '' };
    if (selectedLead.qualified === 'error')
      lead = { ...lead, qualified: 'new' };
    if (selectedLead.survey === 'error') lead = { ...lead, survey: 'new' };
    if (selectedLead.personId === 'error') lead = { ...lead, personId: 'new' };
    dispatch(leadsActions.updateLead(lead));
    setReprocessLeadAlert(false);
  };

  const columns = useMemo(
    () =>
      createTableColumns(
        openViewModal,
        openEditModal,
        null,
        null,
        null,
        openReprocessLeadAlert,
        null,
        campaigns,
        showSensitiveData,
      ),
    [campaigns, showSensitiveData],
  );
  const [hiddenColumns, setHiddenColumns] = useState(
    columns.filter(({ hidden }) => !!hidden).map(({ name }) => name),
  );
  const handleToggleColumn = (name) => {
    setHiddenColumns(addOrRemove(hiddenColumns, name));
  };

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4 float-sm-left">
            {t('Leads with errors')}
          </CardTitle>
          <Row className="align-items-center justify-content-end mb-2">
            <Spinner color="secondary" animation="border" hidden={!loading} />
            <ToggleColumnButton
              columns={columns}
              hiddenColumns={hiddenColumns}
              onToggleColumn={handleToggleColumn}
            />
          </Row>
          <DataTableNext
            loading={loading}
            rows={leads}
            columns={columns}
            filters={filters}
            onFiltersChange={setFilters}
            sorting={sorting}
            onSortingChange={setSorting}
            currentPage={currentPage}
            onCurrentPageChange={setCurrentPage}
            hiddenColumns={hiddenColumns}
            onHiddenColumnsChange={setHiddenColumns}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
          />
        </CardBody>
      </Card>
      <Modal
        size="lg"
        title="Lead"
        isOpen={viewModal}
        toggle={() => setViewModal(!viewModal)}
        showClose
        scrollable={false}>
        <ShowSingleElement element={selectedLead} icon="bx bx-user-pin" />
      </Modal>
      <Modal
        size="xl"
        title="Lead"
        isOpen={editModal}
        toggle={() => setEditModal(!editModal)}
        scrollable={false}>
        <FormLead
          closeModal={() => setEditModal(false)}
          create={!selectedLead}
          loading={loading}
          lead={selectedLead}
          disabledSelectCampaign={selectedLead?.error === ''}
          showMonetizationOrders
        />
      </Modal>
      <Alert
        isOpen={reprocessLeadAlert}
        title="Do you want to reprocess this Lead?"
        confirmBtnLabel="Reprocess"
        onCancel={() => setReprocessLeadAlert(false)}
        onConfirm={handleReprocessLead}>
        There's no coming back!
      </Alert>
    </React.Fragment>
  );
};

export default ErrorLeads;
