import { createSelector } from 'reselect';
import { actualCompany } from './company';
import roles from '../config/roles';

export const hasRole = (roles) =>
  createSelector(
    (state) => state.Auth.admin,
    (admin) => roles.includes(admin.role),
  );

export const isAdmin = createSelector(
  (state) => state.Auth.admin,
  (admin) => admin.role === roles.ADMIN || admin.role === roles.SUPER_ADMIN,
);

export const isManager = createSelector(
  (state) => state.Auth.admin,
  (admin) => admin.role === roles.MANAGER,
);

export const isTeamLeader = createSelector(
  (state) => state.Auth.admin,
  (admin) => admin.role === roles.TEAM_LEADER,
);

export const isOperator = createSelector(
  (state) => state.Auth.admin,
  (admin) => admin.role === roles.OPERATOR,
);

export const isSale = createSelector(
  (state) => state.Auth.admin,
  (admin) => admin.role === roles.SALE,
);

export const userSaleId = createSelector(
  (state) => state.Auth.admin,
  isSale,
  (admin, isSale) => (isSale ? admin.supplierId : null),
);

export const isPublisher = createSelector(
  (state) => state.Auth.admin,
  (admin) => admin.role === roles.PUBLISHER,
);

export const userPublisherId = createSelector(
  (state) => state.Auth.admin,
  isPublisher,
  (admin, isPublisher) => (isPublisher ? admin.supplierId : null),
);

export const isCustomer = createSelector(
  (state) => state.Auth.admin,
  (admin) => admin.role === roles.CUSTOMER,
);

export const userCustomerId = createSelector(
  (state) => state.Auth.admin,
  isCustomer,
  (admin, isCustomer) => (isCustomer ? admin.customerId : null),
);

export const userAcquisitionOrderIds = createSelector(
  (state) => state.Auth.admin,
  actualCompany,
  (admin, company) => {
    if (
      !admin.acquisitionOrderIds ||
      !Array.isArray(admin.acquisitionOrderIds[company.id])
    )
      return null;
    return admin.acquisitionOrderIds[company.id];
  },
);
