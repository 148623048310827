import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Row, Col, Card, CardBody, Container, Spinner } from 'reactstrap';

import * as dailyCampaignInsightsActions from '../../store/googleAd/dailyCampaignInsights/actions';
import * as campaignsActions from '../../store/campaigns/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  totalCosts,
  totalLeads,
  averageCostPerLead,
  drawSeriesDailyGraph,
} from '../../selectors/googleAd/dailyCampaignInsight';
import ReactApexChart from 'react-apexcharts';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DateRangeFilter from '../../components/Common/DateRangeFilter/DateRangeFilter';
import MiniCard from '../../components/Common/MiniCard';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import createTableColumns from '../../components/GoogleAd/DailyCampaignInsights/tableColumns';
import {
  ExportButtonPlacement,
  ToggleColumnButton,
} from '../../components/Common/DataTable/tableActionButtons';
import { addOrRemove } from '../../helpers/sharedFunction';
import { Dots } from 'react-activity';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const DailyCampaignInsights = (props) => {
  const dailyInsights = useSelector(
    (state) => state.DailyGoogleCampaignInsights.insights,
  );
  const campaigns = useSelector((state) => state.Campaign.campaigns);
  const loading = useSelector(
    (state) => state.DailyGoogleCampaignInsights.loading,
  );

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const costs = useSelector(totalCosts());
  const leads = useSelector(totalLeads);
  const costPerLead = useSelector(averageCostPerLead);

  const startDate = useSelector(
    (state) => state.DailyGoogleCampaignInsights.startDate,
  );
  const endDate = useSelector(
    (state) => state.DailyGoogleCampaignInsights.endDate,
  );
  const dataFilters = useSelector(
    (state) => state.DailyGoogleCampaignInsights.filters,
  );
  const momentStartDate = startDate ? moment(startDate) : null;
  const momentEndDate = endDate ? moment(endDate) : null;

  const maxDate = moment.utc();
  const minDate = moment.utc('30/11/2020', 'DD/MM/YYYY');

  const [selectedFilters, setSelectedFilters] = useState({
    startDate: momentStartDate || moment.utc().startOf('month'),
    endDate: momentEndDate || moment.utc(),
    sector: dataFilters?.sector || '',
    campaignIds: dataFilters?.campaignIds || null,
  });

  const dispatch = useDispatch();

  const refresh = useCallback(({ startDate, endDate, sector, campaignIds }) => {
    const selectedStartDate = startDate.utc().startOf('day').toDate();
    const selectedEndDate = endDate.utc().endOf('day').toDate();
    dispatch(
      dailyCampaignInsightsActions.fetchDailyInsights(
        selectedStartDate,
        selectedEndDate,
        {
          sector,
          campaignIds,
        },
      ),
    );
    setSelectedFilters({ startDate, endDate, sector, campaignIds });
  });

  useEffect(() => {
    dailyInsights.length === 0 && refresh(selectedFilters);
    dispatch(campaignsActions.syncCampaigns());
  }, []);

  const dataGraph = useSelector(drawSeriesDailyGraph(selectedFilters));

  const dailyGraph = {
    series: dataGraph.series,
    options: {
      chart: {
        toolbar: 'false',
        type: 'line',
        stacked: false,
      },
      xaxis: {
        type: 'datetime',
        tickAmount: 6,
      },
      tooltip: {
        x: {
          format: 'dddd, dd MMM yyyy',
        },
        fixed: {
          enabled: true,
          position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 10,
          offsetX: 20,
        },
      },
      dataLabels: {
        enabled: !1,
      },
      stroke: {
        curve: 'straight',
        width: [0, 3, 3],
      },
      plotOptions: {
        bar: {
          columnWidth: '30%',
        },
      },
      yaxis: [
        {
          seriesName: 'Spend',
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#008FFB',
          },
          labels: {
            style: {
              colors: '#008FFB',
            },
            formatter: (val) => `€ ${val.toFixed(2)}`,
          },
          title: {
            title: 'Total daily spend (€)',
            style: {
              color: '#008FFB',
              fontSize: '15px',
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        {
          seriesName: 'Leads',
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#00E396',
          },
          labels: {
            style: {
              colors: '#00E396',
            },
            formatter: (val) => val.toFixed(0),
          },
          title: {
            title: 'Daily leads',
            style: {
              color: '#00E396',
              fontSize: '15px',
            },
          },
        },
        {
          seriesName: 'Cost per lead',
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#FEB019',
          },
          labels: {
            style: {
              colors: '#FEB019',
            },
            formatter: (val) => `€ ${val.toFixed(2)}`,
          },
          title: {
            title: 'Cost per lead (€)',
            style: {
              color: '#FEB019',
              fontSize: '15px',
            },
          },
        },
      ],
    },
  };

  const { t } = useTranslation();

  const metrics = [
    {
      title: t('Spend'),
      text: `${isFinite(costs) ? costs.toFixed(2) : 0} €`,
      color: 'primary',
      icon: 'bx bx-money',
    },
    {
      title: t('Leads'),
      text: leads,
      color: 'success',
      icon: 'bx bx-user',
    },
    {
      title: t('Cost per lead'),
      text: `${isFinite(costPerLead) ? costPerLead.toFixed(2) : 0} €`,
      color: 'warning',
      icon: 'mdi mdi-account-cash',
    },
  ];

  const columns = useMemo(() => createTableColumns(campaigns), [campaigns]);
  const [hiddenColumns, setHiddenColumns] = useState(
    columns.filter(({ hidden }) => !!hidden).map(({ name }) => name),
  );
  const handleToggleColumn = (name) => {
    setHiddenColumns(addOrRemove(hiddenColumns, name));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Google Daily Campaign Insights"
            breadcrumbItem="List"
            onRefresh={() => refresh(selectedFilters)}
            loading={loading}
          />
          <DateRangeFilter
            onUpdate={refresh}
            minDate={minDate}
            maxDate={maxDate}
            startDate={selectedFilters.startDate}
            endDate={selectedFilters.endDate}
            loading={loading}
            showFilters
            initialFilters={selectedFilters}
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {loading ? (
                    <Dots />
                  ) : (
                    <div id="line-chart" className="apex-charts" dir="ltr">
                      <ReactApexChart
                        series={dailyGraph.series}
                        options={dailyGraph.options}
                        type="line"
                        height={320}
                      />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            {metrics.map((metric, index) => (
              <Col md="4" key={'metric_' + index}>
                <MiniCard {...metric} loading={loading} />
              </Col>
            ))}
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="align-items-center justify-content-end mb-2">
                    <Spinner
                      color="secondary"
                      animation="border"
                      hidden={!loading}
                    />
                    <ToggleColumnButton
                      columns={columns}
                      hiddenColumns={hiddenColumns}
                      onToggleColumn={handleToggleColumn}
                    />
                    <ExportButtonPlacement />
                  </Row>
                  <DataTableNext
                    loading={loading}
                    rows={dailyInsights}
                    columns={columns}
                    filters={filters}
                    onFiltersChange={setFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    hiddenColumns={hiddenColumns}
                    onHiddenColumnsChange={setHiddenColumns}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DailyCampaignInsights;
