import React, { useEffect } from 'react';
import { Media } from 'reactstrap';
import { selectCompany } from '../../selectors/company';
import * as companiesActions from '../../store/companies/actions';
import { useDispatch, useSelector } from 'react-redux';

const CompanyBox = (props) => {
  const company = useSelector(selectCompany(props.companyId));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(companiesActions.syncCompanies());
  }, []);

  return (
    <div className="d-inline-block mr-4 mini-stats-wid">
      <Media className="header-item">
        <div className="align-self-center mr-2">
          <span className="avatar-xs rounded-circle">
            <i className="bx bxs-shapes font-size-22 text-info"></i>
          </span>
        </div>
        <div className="align-self-center mr-3">
          <h5 className="font-size-15 mt-0 mb-1">
            {company ? company.name : ''}
          </h5>
        </div>
      </Media>
    </div>
  );
};

export default CompanyBox;
