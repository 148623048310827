import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';

import * as leadsActions from '../../store/leads/actions';
import { selectLeadByQuery } from '../../selectors/lead';
import { useDispatch, useSelector } from 'react-redux';
import createTableColumns from '../../components/Leads/tableColumns';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import Modal from '../../components/Common/Modal/Modal';
import ShowSingleElement from '../../components/Common/ShowSingleElement';
import moment from 'moment';

const LeadsQueryTable = ({ query }) => {
  const leads = useSelector(selectLeadByQuery(query.logic));
  const loading = useSelector((state) => state.Leads.loading);

  const [selectedLead, setSelectedLead] = useState(null);
  const [viewModal, setViewModal] = useState(false);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const dateRange = {
    startDate: moment.utc().startOf('month').startOf('day').toDate(),
    endDate: moment.utc().endOf('day').toDate(),
  };

  const dispatch = useDispatch();

  const openViewModal = (lead) => {
    setSelectedLead(lead);
    setViewModal(true);
  };

  const columns = createTableColumns(openViewModal);

  useEffect(() => {
    leads.length === 0 &&
      dispatch(leadsActions.fetchLeads(dateRange.startDate, dateRange.endDate));
  }, [query]);

  return (
    <>
      <Row>
        <Col xl="12">
          <Card>
            <CardBody>
              <DataTableNext
                loading={loading}
                rows={leads}
                columns={columns}
                filters={filters}
                onFiltersChange={setFilters}
                sorting={sorting}
                onSortingChange={setSorting}
                currentPage={currentPage}
                onCurrentPageChange={setCurrentPage}
                pageSize={pageSize}
                onPageSizeChange={setPageSize}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal
        size="lg"
        title="Lead"
        isOpen={viewModal}
        toggle={() => setViewModal(!viewModal)}
        showClose
        scrollable={false}>
        <ShowSingleElement element={selectedLead} icon="bx bx-user-pin" />
      </Modal>
    </>
  );
};

export default LeadsQueryTable;
